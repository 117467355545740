import './Styles/App.scss';
import Messages from "./Components/Messages";

export default function App() {
  return (
    <div>
        <Messages />
    </div>
  );
}
